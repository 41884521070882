* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10pt;
}

@import url('https://fonts.googleapis.com/css?family=Raleway:400,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

@font-face {
  font-family: 'TuesdayNight';
  font-style: normal;
  font-weight: normal;
  src: local('TuesdayNight'), url('/font/TuesdayNight-Regular.otf') format('truetype');
}

:root {
  --primaryColor: #af9a7d;
  --primaryLogoColor: #FE390D;
  --mainWhite: #fff;
  --offWhite: #f8f8f8;
  --mainBlack: #212121;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

/* globals */
body {
  padding-top: 60px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
  font-size: 10px;
}

h1 {
  font-size: 3.5rem;
}

.font-bold {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
}


.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}

.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}

.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}

.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}

.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}

/* end of globals */

/* Navbar */
.custom-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.4rem 2rem;
  background: white;
  z-index: 100;
}

.custom-nav-header {
  display: flex;
  justify-content: space-between;
}

.custom-nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.custom-nav-icon {
  font-size: 1.5rem;
  color: var(--primaryLogoColor);
}

.custom-nav-close-icon {
  font-size: 2.5rem;
  color: #9C9C9C;
}

.logo-img {
  height: 50px;
}

.custom-nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;

  display: block;
  text-decoration: none;
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  letter-spacing: var(--mainSpacing);
  color: #9C9C9C;
  font-family: 'Open Sans', sans-serif;
}

.custom-nav-links a {
  display: block;
  text-decoration: none;
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  letter-spacing: var(--mainSpacing);
  color: #9C9C9C;
  font-family: 'Open Sans', sans-serif;
  margin: 20px 0;
}

.custom-nav-links a:hover {
  color: var(--primaryLogoColor);
}

.show-nav {
  height: 100%;
}

.show-link {
  display: none;
}

.cross-close {
  text-align: right;
  margin: 15px 0;
}

.mobile-show {
  display: block;
}


@media screen and (min-width: 768px) {
  .custom-nav-btn {
    display: none;
  }

  .mobile-show {
    display: none;
  }

  .custom-nav-center {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .custom-nav-links {
    height: auto;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .custom-nav-links a {
    padding: 0.5rem 0;
    margin: 0;
  }

  .show-link {
    display: block;
  }
}

/* end of navbar */


/* Footer */
.footer {
  max-width: 1170px;
  margin: 0 auto;
}

.custom-footer-center {
  display: block;
  color: #F8F8F8;
}

.custom-footer-links {
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.custom-footer-links li {
  font-size: .8rem;
  ;
}

.custom-footer-links a {
  display: block;
  text-decoration: none;
  transition: var(--mainTransition);
  font-size: .8rem;
  color: #9C9C9C;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  letter-spacing: 0.36px;
  text-align: right;

  margin-right: 10px;
  cursor: pointer;
}

.custom-footer-links a:hover {
  color: var(--primaryLogoColor);
}

.footer-line {
  width: 80%;
  margin: 0 auto;
}

.footer-copyright {
  display: flex;
  justify-content: center !important;
  color: #9C9C9C;
}

@media screen and (min-width: 768px) {
  .custom-footer-center {
    display: flex;
    justify-content: space-between;
    max-width: 1170px;
    margin: 0 auto;
  }

  .custom-footer-links {
    height: auto;
    display: flex;
    align-items: center;
  }

  .custom-footer-links a {
    font-size: 1rem;
    align-items: center;
  }

  .custom-footer-links li {
    font-size: 1rem;
    ;
  }
}

/* end of footer */

/* main banner */
.banner-bottom {
  position: relative;
  top: -65px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  height: 0;
}

.mobile-banner-bottom {
  background-color: #f8f8f8;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c2c2c;
  display: none;
  background-color: #f8f8f8;
  justify-content: center;
}

.mobile-banner-bottom .banner-title-text {
  margin: 5px 0;
  padding-right: 25px;
  padding-left: 25px;
}

.banner-bottom .banner-title-text {
  font-size: 2.2rem;
}

@media screen and (max-width: 1000px) {
  .banner-bottom .banner-title-text {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .banner-bottom {
    display: none;
  }

  .mobile-banner-bottom {
    display: flex;
  }
}


/* project section */
.project-section {
  padding: calc(.5rem + 60px) 2rem 2rem 2rem;
  background-color: var(--offWhite);
}

.project-image-card {
  margin: .5rem 0;
}

.project-image {
  max-width: 100%;
}

.category-title {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: center;
  color: black;
  margin: 2rem 0;
}

.category-title ul {
  list-style-type: none;
  display: block;
}

.category-title ul li {
  display: inline-block;
  font-size: 1.2rem;
  margin: 0 1rem;
  letter-spacing: .8px;
  cursor: pointer;
}

.project-show-button {
  margin: 3rem auto 1rem auto;
}

.show-button {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  ;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 7px;
  background-color: #ff2500;
  padding: .5rem 1.5rem;
}

.project-show-button-row {
  justify-content: center;
}

/* About section */
.about-section {
  padding: calc(.5rem + 60px) 2rem 0 2rem;
}

.about-section .about-container {
  padding: 5rem;
  padding-bottom: 0;
}

.about-section .about-container .content-box {
  margin: 3rem 0;
}

.about-section .about-container .content {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.6px;
  color: #58595b;
  margin-top: 1rem;
}

.about-section .about-container .content .points {
  padding: 2rem;
}

.about-section .about-container .content .highlight {
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: normal;
  color: #3e3e3e;
}

.red-highlight {
  color: #ee3124;
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: normal;
}


.about-section .about-container .box {
  background-color: #f8f8f8;
  height: 100%;
}

.about-section .about-container .box .quote-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding: 0 5%;
}

.about-section .about-container .box .quote {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #414042;
  width: 70%;
}

.about-section .about-container .box .quote-signature {
  font-family: TuesdayNight;
  font-size: 2.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.96;
  letter-spacing: normal;
  text-align: center;
  color: #ef4123;
  margin-top: 1rem;
}

.team-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 3rem 0;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: center;
  color: #000000;

  cursor: pointer;
}

.custom-team-icon {
  font-size: 1.5rem;
  color: var(--primaryLogoColor);
  margin: 5px 0 0 10px;
}

@media screen and (max-width: 768px) {
  .about-section {
    padding: calc(1rem + 60px) 2rem 0 2rem;
  }

  .about-section .about-container {
    padding: 0;
  }

  .about-section .about-container .content {
    font-size: 1rem;
    line-height: 1.6;
  }

  .about-section .about-container .content .points {
    padding: 2rem;
  }

  .about-section .about-container .content .highlight {
    font-size: 1.8rem;
  }

  .red-highlight {
    font-size: 1.8rem;
  }

  .about-section .about-container .box .quote-content {
    padding: 10% 0;
  }

  .about-section .about-container .box .quote {
    font-size: 1.5rem;
    width: 90%;
  }

  .about-section .about-container .box .quote-signature {
    font-size: 2rem;
    margin-top: 2rem;
  }

  .team-button {
    margin: 10px 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    margin: 5px 0 4px 6px;
  }

}

/* what we do section */
.what-we-do-section {
  padding: calc(.5rem + 60px) 2rem 0 2rem;
}

.what-we-do-section .what-we-do-heading {
  display: flex;
  align-items: center;
  padding: 0 5rem;
}

.what-we-do-section .what-we-do-heading .what-we-do-title {
  font-family: Raleway;
  font-size: 2.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.what-we-do-horizontal-line {
  width: 70%;
  margin: 0 auto;
}

.what-we-do-section .what-we-do-container {
  padding: 5rem;
  padding-top: 2.5rem;
}



.box-container {
  height: 100%;
}

.box-container .box-image {
  padding-bottom: 1rem;
  background-size: cover;
  display: flex;
  height: 450px;
  align-items: flex-end;
  padding-left: 2rem;
}

.box-container .image-1 {
  background-image: url("/images/interior.jpg");
}

.box-container .image-2 {
  background-image: url("/images/design.jpg");
}

.box-container .image-3 {
  background-image: url("/images/furniture.jpg");
}

.box-container .image-4 {
  background-image: url("/images/architecture.jpg");
}

.box-container .box-image .points ul {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.box-container .box-image .points li {
  font-size: 1rem;
  line-height: 2;
}

.box-container .box-footer {
  text-align: center;
  padding: 10px 0;
  box-shadow: 0px 1px 5px 0.1px rgba(0, 0, 0, 0.15);
  background-color: #f6f6f6;

  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #414042;

  height: calc(100% - 450px);
}

@media screen and (max-width: 768px) {

  /* what we do section */
  .what-we-do-section {
    padding: calc(.5rem + 60px) 15px 0 15px;
  }

  .what-we-do-section .what-we-do-heading {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .what-we-do-section .what-we-do-heading .what-we-do-title {
    font-size: 2rem;
    line-height: 1;
  }

  .what-we-do-horizontal-line {
    width: 50%;
    margin: 0 auto;
  }

  .what-we-do-section .what-we-do-container {
    margin: 2rem 0;
    padding: 0;
  }

  .box-container .box-image {
    height: 275px;
  }

  .box-container .box-image .points li {
    font-size: .8rem;
    line-height: 2;
  }

  .box-container .box-footer {
    font-size: 1.2rem;
    height: calc(100% - 275px);
  }

}

/* Designs and build section */
.design-section {
  background-image: url("/images/Stambha-Artboard1_48.jpg");
  padding: calc(.5rem + 60px) 2rem 2rem 2rem;
}

.design-container {
  padding: 5rem;
  padding-top: 2rem;
}

.design-section-heading {
  font-family: Raleway;
  font-size: 3rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.design-container .horizontal-line-heading {
  text-align: center;
  width: 6%;
  margin: 10px auto;
  border: 2px solid #FF903E;
}

.design-container .design-section-subheading {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  margin: 2rem 0;
}

.design-section .card-row .card {
  padding: 10px;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  height: 100%;
}

.design-section .card-row .card .index {
  font-family: 'Open Sans', sans-serif;
  font-size: 4rem;
  font-weight: bold;
  line-height: 0.85;
  color: rgba(33, 33, 33, 0.17);

  align-self: flex-start;
}

.design-section .card-row .card .icon {
  align-self: center;
}

.design-section .card-row .card .icon .image {
  width: 50px;
  margin: 15px;
}

.design-section .card-row .card .card-heading {
  width: 65%;
  font-size: 1.6rem;
  text-align: center;

  font-weight: bold;
  line-height: 1.18;
  color: #212121;

  align-self: center;
}

.design-section .card-row .card .card-points {
  height: 120px;
  display: flex;
  align-items: flex-end;
}

.design-section .card-row .card .card-points .points {
  list-style: none;
}

.design-section .card-row .card .card-points .points li {
  font-size: 1.1rem;
}

.why-us-container {
  background-image: url(/images/why-us-background.jpg);
  padding: calc(.5rem + 60px) 2rem 5rem 2rem;
}

.why-us-row .why-us-heading {
  display: flex;
  align-items: center;
  padding: 0 5rem;

  margin-bottom: 3rem;
}

.why-us-row .why-us-heading .why-us-title {
  font-family: Raleway;
  font-size: 3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.why-us-row .why-us-heading .why-us-horizontal-line {
  width: 80%;
  margin: 0 auto;
}

.why-us-row .why-us-card {
  display: flex;
  align-items: flex-start;
  margin: 2.5rem 0;
}

.why-us-row .why-us-card .icon {
  margin-right: 20px;
}

.why-us-row .why-us-card .icon .image {
  width: 50px;
}

.why-us-row .why-us-card .content {
  display: flex;
  flex-direction: column;
}

.why-us-row .why-us-card .content .card-heading {
  font-family: Raleway;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.why-us-row .why-us-card .content .card-subheading {
  font-family: Raleway;
  font-size: .8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #58595b;
}

@media screen and (max-width: 768px) {

  /* Designs and build section */
  .design-section {
    background-image: url("/images/Stambha-Artboard1_48.jpg");
    padding: calc(.5rem + 60px) 15px 0 15px;
  }

  .design-container {
    padding: 0;
  }

  .design-section-heading {
    font-size: 1.8rem;
  }

  .design-container .horizontal-line-heading {
    width: 20%;
  }

  .design-container .design-section-subheading {
    font-size: 1rem;
    margin: 2rem 0;
  }

  .design-section .card-row .card .index {
    font-size: 3rem;
  }

  .design-section .card-row .card .icon .image {
    margin: 0;
  }

  .design-section .card-row .card .card-points .points li {
    font-size: .8rem;
  }

  .design-section .card-row .card .card-heading {
    width: 90%;
    font-size: 1rem;
  }

  .design-section .card-row .card .card-points {
    height: 80px;
  }

  .why-us-container {
    padding: calc(.5rem + 60px) 15px 2rem 15px;
  }

  .why-us-row .why-us-heading {
    padding: 0 15px;
    margin-bottom: 2rem;
  }

  .why-us-row .why-us-heading .why-us-title {
    font-size: 2rem;
  }

  .why-us-row .why-us-heading .why-us-horizontal-line {
    width: 70%;
  }

  .why-us-row .why-us-card {
    margin: 0;
  }

  .why-us-row .why-us-card .content .card-heading {
    font-size: 1rem;
  }

  .why-us-row .why-us-card .content .card-subheading {
    font-size: .8rem;
  }

}

/* pricing section */
.pricing-container .price-card {
  background-image: url("/images/pricing-1.jpg");
  padding: 0;
}

.pricing-container .second {
  background-image: url("/images/pricing-2.jpg");
  padding: 0;
}

.pricing-container .price-card .price-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  padding: 12%;

  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.pricing-container .price-card .price-content h1 {
  font-size: 3rem;
}

.pricing-container .price-card .price-content h3 {
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .pricing-container .price-card .price-content h1 {
    font-size: 1.5rem;
  }

  .pricing-container .price-card .price-content h3 {
    font-size: 1.2rem;
  }
}


/* testomonial */
.testimonial {
  background: white;
  padding: 5rem 2rem;
}

.testimonial .heading {
  margin-bottom: 5%;
}

.testimonial .content {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;

  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.testimonial .content h6 {
  font-size: 2rem;
  color: #333333;
  margin: 0;
}

.testimonial .content p {
  font-size: 1.2rem;
  color: #86878b;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .testimonial {
    padding: 2.5rem 2rem;
  }

  .testimonial .heading {
    margin-bottom: 10%;
  }

  .testimonial .content {
    width: 80%;
  }

  .testimonial .content h6 {
    font-size: 1.5rem;
  }

  .testimonial .content p {
    font-size: 1rem;
  }

}

/* contact us  */
.contact-us-section .contact-us-card {
  background-image: url("/images/contact-us.jpg");
  padding: calc(.5rem + 60px) 15px 15px 15px;
}

.contact-us-section .contact-us-card .contact-us-body {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5%;
  width: 80%;
  margin: 0 auto;
}

.contact-us-body-heading h1 {
  font-family: Raleway;
  font-size: 3rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--mainWhite);
}

.contact-us-body-heading .horizontal-line-heading {
  text-align: left;
  width: 10%;
  border: 2px solid #FF903E;
}

.contact-us-section .contact-us-card .contact-us-body .address,
.contact,
.email,
.website {
  color: white;
  font-size: 14px;
  display: flex;
  padding: 12px 0;
}

.contact-us-section .contact-us-card .contact-us-body .label {
  font-size: 15px;
  font-weight: bold;
  color: #FF903E;
  min-width: 70px;
}

.contact-us-section .contact-us-card .contact-us-body .value {
  font-size: 15px;
}

.contact-us-section .contact-us-card .contact-us-body .value ul {
  display: inline-block;
  list-style: none;
}

.contact-us-section .contact-us-card .contact-us-body .value li {
  font-size: 15px;
}

.contact-us-section .contact-us-card .contact-us-body .value a {
  color: white;
  font-size: 15px;
}

.map-canvas {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .map-canvas {
    height: 300px;
  }

  .contact-us-section .contact-us-card .contact-us-body {
    padding: 5% 0;
    width: 90%;
    margin: 0 auto;
  }


  .contact-us-section .contact-us-card .contact-us-body .address,
  .contact,
  .email,
  .website {
    font-size: 12px;
    padding: 6px 0;
  }

  .contact-us-section .contact-us-card .contact-us-body .value a {
    color: white;
    font-size: 12px;
  }


  .contact-us-section .contact-us-card .contact-us-body .label {
    font-size: 12px;
  }

  .contact-us-section .contact-us-card .contact-us-body .value li {
    font-size: 12px;
  }
}

/* heading */
.heading {
  font-family: Raleway;
  font-size: 5rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--mainBlack);
}

.heading .horizontal-line-heading {
  text-align: center;
  width: 6%;
  margin: 10px auto;
  border: 2px solid #FF903E;
}

@media screen and (max-width: 768px) {
  .heading .horizontal-line-heading {
    width: 25%;
  }

}


/* Team Pages */
.team-banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.team-banner h1 {
  font-size: 4rem;
  font-family: 'Open Sans', sans-serif;
}

.defaultTeamBanner {
  min-height: calc(100vh - 66px);
  background: url("/images/teams/banner.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-body .team-card {
  margin: 5% 0;
}

.team-body .team-card .picture {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  width: 100%;
  padding: 0;
}

.team-body .team-card .picture .team-picture {
  width: 100%;
}

.team-body .team-card .team-content {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.team-body .team-card .team-content h1 {
  font-size: 2.5rem;
  font-weight: 900;
  color: #000000;
}

.team-body .team-card .team-content h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.33;
  color: #ee3124;
}

.team-body .team-card .team-content p {
  font-size: 1.1rem;
  line-height: 1.88;
  text-align: justify;
  color: #58595b;
}

.team-right {
  text-align: right;
}

@media screen and (max-width: 768px) {

  .team-banner h1 {
    font-size: 2.5rem;
  }

  .team-body .team-card {
    margin: 10% 0;
  }

  .team-body .team-card .team-content h1 {
    font-size: 2rem;
  }

  .team-body .team-card .team-content h3 {
    font-size: 1.5rem;
  }

  .team-body .team-card .team-content p {
    font-size: 1rem;
  }

  .team-right {
    order: 1;
    text-align: left;
  }
}

/* Single Portfolio  */
.single-project-banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.defaultProjectBanner {
  min-height: calc(100vh - 66px);
  background: url("/images/portfolio/Banner.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-on {
  display: none;
}

.single-project-banner .project-card {
  background: white;
  color: black;
  position: absolute;
  bottom: -100px;
  width: 50%;
  left: 100px;

  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333132;
  text-align: left;

  box-shadow: 1.1px 10.9px 17.6px 0.4px rgba(0, 0, 0, 0.04);
  padding: 30px;
}

.single-project-banner .project-card h1 {
  font-size: 2.4rem;
  margin-bottom: 20px;
}

.single-project-banner .project-card p {
  font-size: 1.3rem;
  font-weight: 300;
  color: #58595b;
  margin-bottom: 40px;
}

.single-project-banner .project-card h6 {
  font-size: 1.6rem;
  font-weight: bold;
}

.single-project-body {
  margin-top: 10%;
}

.single-project-image {
  width: 100%;
  margin-bottom: 5%;
}

.single-project-footer .project-footer {
  display: flex;
  align-items: center;
}

.single-project-footer .project-footer .back-image {
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.single-project-footer .project-footer .content {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  margin-left: 20px;
}

.single-project-footer .project-footer .content p {
  font-size: 1rem;
  color: #333132;
}

.single-project-footer .project-footer .content h6 {
  color: #ee3124;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {

  .defaultProjectBanner {
    min-height: 25vh;
  }

  .mobile-off {
    display: none;
  }

  .mobile-on {
    display: block
  }

  .project-card {
    position: relative;
    box-shadow: 1.1px 10.9px 17.6px 0.4px rgba(0, 0, 0, 0.04);
    padding: 30px;
  }

  .project-card h1 {
    font-size: 1.7rem;
    margin-bottom: 30px;
  }

  .project-card p {
    font-size: 1rem;
    margin-bottom: 50px;
  }

  .project-card h6 {
    font-size: 1.2rem;
  }

  .single-project-footer .project-footer .back-image {
    height: 60px;
    width: 60px;
  }

  .single-project-footer .project-footer .content p {
    font-size: .8rem;
  }

  .single-project-footer .project-footer .content h6 {
    color: #ee3124;
  }
}

.zero-padding {
  padding-left: 0px;
  padding-right: 0px;
}

@media screen and (max-width: 768px) {
  .minor-padding {
    padding: 15px 5px;
  }

  .zero--mobile-padding {
    padding-left: 0px;
    padding-right: 0px;
  }

  .order-xs-2 {
    order: 2;
  }

  .order-xs-1 {
    order: 1;
  }
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: darkgray !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: darkgray !important;
}

.carousel .slide {
  background: white !important;
}

.instagram-icon {
  height: 100%;
  margin-top: 4px;
}